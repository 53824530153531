<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.update_movement") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
        >{{ $t("message.refresh") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="resetForm('form')"
        >{{ $t("message.close") }}</el-button
      >
    </header>

    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-card class="box-card mb-4">
          <el-row :gutter="40">
            <el-col :sm="12">
              <el-form-item :label="$t('message.warehouse')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <mainwarehouse
                        ref="mwareh"
                        v-model="form.mainwarehouse_id"
                        :mainwarehouse_id="form.mainwarehouse_id"
                      ></mainwarehouse>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="$t('message.warehouse_movement')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <warehouse
                        ref="wareh"
                        v-model="form.warehouse_id"
                        :warehouse_id="form.warehouse_id"
                      ></warehouse>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-table border :data="items" :row-class-name="tableRowClassName">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{ scope.row.product.name }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.quantity" @input="correctNumber(scope.row, 'quantity')"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.available')" align="center">
                  <template slot-scope="scope">{{ scope.row.available | formatMoney }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.price')" align="center">
                  <template slot-scope="scope">{{ scope.row.price | formatMoney }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        @click="removeWriteOffItem(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>

          <el-row :gutter="25">
            <el-col :span="11"  class="mt-3">
              <el-form-item v-if="form.mainwarehouse_id">
                <warehouse_products @append="append" v-model="form.product_id" :product_id="form.product_id" :warehouse_id="form.mainwarehouse_id"/>
              </el-form-item>
              <el-form-item v-else>
                <el-select v-model="value" disabled :placeholder="$t('message.products')">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import products from "@/components/selects/products";
import form from "@/utils/mixins/form";
import mainwarehouse from "@/components/selects/mainwarehouse";
import warehouse from "@/components/selects/warehouse";
import warehouse_products from "@/components/selects/warehouse_products.vue";
import {strToInt} from "@/filters";

export default {
  mixins: [drawerChild, drawer, form],
  components: {
    warehouse_products,
    mainwarehouse,
    warehouse,
    products,
  },
  props: ["requirement"],
  data() {
    return {
      qrCode: null,
      loadingItems: false,
      options: [],
      old_items: [],
      items: [],
      value: '',
    };
  },
  computed: {
    ...mapGetters({
      model: "requirements/model",
      rules: "requirements/rules",
      columns: "requirements/columns",
    }),
  },
  methods: {
    strToInt,
    ...mapActions({
      showRequirement: "requirements/show",
      searchByCode: "products/searchByCode",
      updateWriteOff: "requirements/update",
      destroyItem: "requirements/destroyItem",
    }),
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    append(product) {
      const isDuplicate = this.items.some((item) =>
          (item.product_id === product.product_id)&&
          (item.price === product.price)&&
          (item.serial_number === product.serial_number));

      if (!isDuplicate) {
        this.items.push({
          is_new: true,
          product_id: product.product_id,
          available: product.available ? Number(product.available) : 0,
          price: product.price,
          serial_number: product.serial_number,
          quantity: 0,
          product: {
            name: product.product.name,
          },
        });
      } else {
        this.$notify({
          title: 'Внимание',
          type: "warning",
          offset: 130,
          message: "Этот продукт уже выбран"
        });
      }
    },
    afterOpened() {
      this.$refs.mwareh.disableSelect();
      this.$refs.wareh.disableSelect();
      this.load();
    },
    load() {
      this.loadingItems = true;
      this.showRequirement(this.requirement.id)
        .then((response) => {
          if (response.data.requirement.items) {
            const items = response.data.requirement.items;console.log(items);
            items.forEach((item) => {
              this.items.push({
                id: item.id,
                is_new: false,
                product_id: item.product_id,
                price: item.price,
                serial_number: item.serial_number,
                quantity: item.quantity,
                available: item.product.available ? item.product.available : 0,
                product: {
                  name: item.product.name,
                },
              });
            })
            this.loadingItems = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    submit() {
      this.items.forEach(item => {
          item.quantity = this.strToInt(item.quantity);
      })

      const payload = {
        id: this.form.id,
        mainwarehouse_id: this.form.mainwarehouse_id,
        warehouse_id: this.form.warehouse_id,
        old_items: this.items.filter((item) => !item.is_new),
        new_items: this.items.filter((item) => item.is_new),
      };

      this.updateWriteOff(payload)
        .then((response) => {
          this.$alert(response.message);
          this.close();
        })
        .catch();
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    destroyWriteOffItem(item) {
      let id = item.id;
      this.destroyItem(id)
        .then(() => {
          this.items.splice(this.items.indexOf(item), 1);
        })
        .catch((error) => {
          this.$alert(error);
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return "warning-row";
      }
    },
    correctNumber(obj, propertyName) {
      let unformattedValue = obj[propertyName].toString().replace(/\s/g, '');
      let formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      this.$set(obj, propertyName, formattedValue);
    }
  },
};
</script>

<style>
.el-table tr.warning-row {
  background-color: #e6a23c;
}
</style>

<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.requirements") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            />
            <el-date-picker
              class="ml-3"
              size="mini"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            />
            <el-date-picker
              class="ml-3"
              size="mini"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            />
          </div>
        </div>
        <div
            class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}</el-button
          >
          <export-excel
            class="btn excel_btn mr-2"
            :data="list"
            worksheet="Пользователи"
            name="Пользователи.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete"
              >Excel</el-button
            >
          </export-excel>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}
                </el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination :pagination="pagination" @c-change="updatePagination"/>
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"/>
            <crm-th :column="columns.mainwarehouse_id" :sort="sort" @c-change="updateSort"/>
            <crm-th :column="columns.warehouse_id" :sort="sort" @c-change="updateSort"/>
            <crm-th :column="columns.created_at" :sort="sort" @c-change="updateSort"/>
            <crm-th :column="columns.user_id" :sort="sort" @c-change="updateSort"/>
            <crm-th :column="columns.status"/>
            <crm-th :column="columns.wlec"/>
            <crm-th :column="columns.settings"/>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
            >
            </el-input>
          </th>
            <th v-if="columns.mainwarehouse_id.show">
              <el-select
                  clearable
                  size="mini"
                  v-model="filterForm.mainwarehouse_id"
                  :placeholder="columns.mainwarehouse_id.title"
              >
                <el-option
                    v-for="item in warehouses"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.warehouse_id.show">
              <el-select
                  clearable
                  size="mini"
                  v-model="filterForm.warehouse_id"
                  :placeholder="columns.warehouse_id.title"
              >
                <el-option
                    v-for="item in warehouses"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                  :placeholder="columns.created_at.title"
                  v-model="filterForm.created_at"
                  size="mini"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.user_id.show">
              <el-select
                  clearable
                  size="mini"
                  v-model="filterForm.user_id"
                  :placeholder="columns.user_id.title"
              >
                <el-option
                    v-for="item in users"
                    :key="item.id"
                    :label="(item.surname || '') + ' ' + (item.name || '')"
                    :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.status.show"/>
            <th v-if="columns.wlec.show"/>
            <th v-if="columns.settings.show"/>
        </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="requirement in list" :key="requirement.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ requirement.id }}</td>
            <td v-if="columns.mainwarehouse_id.show">
              {{ requirement.mainwarehouse ? requirement.mainwarehouse.name : "" }}
            </td>
            <td v-if="columns.warehouse_id.show">
              {{ requirement.warehouse ? requirement.warehouse.name : "" }}
            </td>
            <td v-if="columns.created_at.show">{{ requirement.created_at }}</td>
            <td v-if="columns.user_id.show">
              {{ requirement.user ? requirement.user.name : "" }}
            </td>
            <td v-if="columns.status.show">
              {{ $t(`message.` + requirement.status) }}
            </td>
            <td v-if="columns.wlec.show">
              <div @click="showItems(requirement)" class="vuee">
                {{ $t("message.showing") }}
              </div>
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                v-if="isPreparing(requirement)"
                :model="requirement"
                name="requirements"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              />
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="85%"
      :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <create-form
        :drawer-name="drawer.create.name"
        :ref="drawer.create.component"
      />
    </el-drawer>

    <el-drawer
      size="85%"
      :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :with-header="false"
      :ref="drawer.update.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <update-form
        :drawer-name="drawer.update.name"
        :ref="drawer.update.component"
        :requirement="selectedItem"
      />
    </el-drawer>

    <el-dialog
      :title="dialog_title"
      :visible.sync="isItemsModelVisible"
      width="80%"
      :before-close="onCloseDialog"
    >
      <el-card shadow="always">
        <table class="table table-striped table-bordered mb-0" v-if="requirementInfo" v-loading="loadingData">
          <tbody>
            <tr>
              <td>{{ $t('message.whence') }}:</td>
              <td>{{ $t('message.where_to') }}:</td>
              <td>{{ $t('message.user') }}:</td>
              <td>{{ $t('message.status') }}:</td>
            </tr>
            <tr>
              <td>{{ requirementInfo.mainwarehouse ? requirementInfo.mainwarehouse.name : '-'}}</td>
              <td>{{ requirementInfo.warehouse ? requirementInfo.warehouse.name : '-' }}</td>
              <td>{{ requirementInfo.user ? requirementInfo.user.surname + ' ' + requirementInfo.user.name : '-'}}</td>
              <td>{{ $t(`message.` + requirementInfo.status) }}</td>
            </tr>
          </tbody>
        </table>
      </el-card>
      <el-card>
        <el-table
          :data="products"
          style="width: 100%"
          border
        >
          <el-table-column label="#" width="30">
            <template slot-scope="scope">{{
                scope.$index + 1
              }}</template>
          </el-table-column>
          <el-table-column
              prop="product.name"
              :label="$t('message.product')"
          />
          <el-table-column
            prop="quantity"
            :label="$t('message.quantity')"
          >
            <template slot-scope="scope">
              {{ scope.row.quantity | formatMoney }}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            :label="$t('message.price')"
          />
          <el-table-column
            prop="serial_number"
            :label="$t('message.serial_number')"
          />
        </el-table>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="isSent(requirementInfo)"
          size="mini"
          type="success"
          @click="set_status(requirementInfo, 'confirmed')"
        >{{
            $t(`message.confirm`)
          }}</el-button>
        <el-button
          v-if="isSent(requirementInfo)"
          size="mini"
          type="danger"
          @click="set_status(requirementInfo, 'rejected')"
        >{{
            $t(`message.reject`)
          }}</el-button>
        <el-button
          v-if="isPreparing(requirementInfo)"
          size="mini"
          type="primary"
          @click="set_status(requirementInfo, 'sent')"
        >{{
            $t(`message.send`)
          }}</el-button>
        <el-button
            v-if="isConfirmed(requirementInfo)&&!hasMoving(requirementInfo)"
            size="mini"
            type="warning"
            @click="createMoving(requirementInfo)"
        >{{
            $t(`message.movement`)
          }}</el-button>
        <el-button
            v-if="isConfirmed(requirementInfo)"
            size="mini"
            type="success"
            @click="print(requirementInfo)"
        >{{
            $t(`message.print`)
          }}</el-button>
      </div>
    </el-dialog>

    <el-drawer size="50%" :wrapperClosable="false" :visible.sync="tableID2" :with-header="false">
      <CrmLook />
    </el-drawer>
  </div>
</template>
<script>
import CreateForm from "./components/CreateForm";
import UpdateForm from "./components/UpdateForm";
import CrmLook from "./components/crm-look";
import { i18n } from "@/utils/i18n";

import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import warehouses from "@/components/inventory-select/warehouses";
import CrmTh from "@/components/crm/include/crm-th.vue";
import requirement from "@/router/modules/requirement";
import {formatMoney} from "../../filters";

export default {
  mixins: [list, drawer],
  name: "requirement",
  data() {
    return {
      data_s: "",
      loadingData: false,
      tableID2: false,
      tableID: false,
      tableUser: false,
      requirementInfo: null,
      products: [],
      actualUser: null,
      dialog_title: null,

      isItemsModelVisible: false,
      isLoadingItems: false,
      selectedItem: null,
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        update: {
          name: "update",
          component: "componentUpdate",
          status: false,
        },
      },
      actions: ["edit", "delete"],
    };
  },
  components: {
    CrmTh,
    CrmLook,
    CreateForm,
    UpdateForm,
    warehouses,
  },
  computed: {
    ...mapGetters({
      list: "requirements/list",
      columns: "requirements/columns",
      pagination: "requirements/pagination",
      filter: "requirements/filter",
      sort: "requirements/sort",
      warehouses: "warehouses/list",
      users: "users/list",
    }),
  },
  async mounted() {
    await this.loadWarehouses();
    await this.loadUsers();
    this.loadUser()
      .then(res => {
        this.actualUser = res;
      });
  },
  methods: {
    formatMoney,
    ...mapActions({
      getRequirements: "requirements/index",
      updateSort: "requirements/updateSort",
      updateFilter: "requirements/updateFilter",
      updateColumn: "requirements/updateColumn",
      updatePagination: "requirements/updatePagination",
      showRequirement: "requirements/show",
      setStatusRequirement: "requirements/setStatus",
      save: "movings/store",
      print: "requirements/print",
      empty: "requirements/empty",
      delete: "requirements/destroy",
      refresh: "requirements/refreshData",
      loadWarehouses: "warehouses/index",
      loadUsers: "users/index",
      loadUser: "auth/getAuth",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getRequirements(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      this.selectedItem = model;
      this.drawer.update.status = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showItems(model) {
      if (model.moving_id !== null) {
        this.dialog_title = i18n.t('message.products') + ' (' + i18n.t(`message.movement`) + ' ' + i18n.t(`message.created`) + ')';
      } else {
        this.dialog_title = i18n.t('message.products');
      }
      this.requirementInfo = model;
      this.products = model.items;
      this.isItemsModelVisible = true;
    },
    onCloseDialog(done) {
      done();
      this.empty();
      this.isLoadingItems = false;
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    drawerClosed(ref) {
      this.$refs[ref].closed();
      this.refreshData();
    },
    open() {
      this.$confirm(
        i18n.t("message.delete_notification"),
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: i18n.t("message.delete_success"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("message.delete_calcel"),
          });
        });
    },
    isSent(model) {
      if (model && model.status === 'sent') {
        return ['admin', 'sklad'].includes(this.actualUser.role.slug);
      } else return false;
    },
    isPreparing(model) {
      return model && model.status === 'preparing';
    },
    isConfirmed(model) {
      return model && model.status === 'confirmed';
    },
    hasMoving(model) {
      return model.moving_id !== null;
    },
    set_status(requirement, status) {
      const query = {
        id: requirement.id,
        status: status,
      };
      this.setStatusRequirement(query)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            message: res.message,
            type: 'success',
            offset: 130,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(fin => {
          this.isItemsModelVisible = false;
          this.refreshData();
        })
    },
    print(requirement) {
      this.waitingPrint = true
      let vm = this
      var jqxhr = $.get(
          process.env.VUE_APP_URL + `requirements/print/${requirement.id}`,
          function () {}
      )
          .done(function (res) {
            vm.waitingPrint = false
            const WinPrint = window.open(
              "",
              "",
              "left=0,top=0,toolbar=0,scrollbars=0,status=0"
            )
            WinPrint.document.write(res)
            WinPrint.document.close()
            WinPrint.focus()
            setTimeout(() => {
              WinPrint.print()
              WinPrint.close()
            }, 1000)
          })
          .fail(function () {
            alert(i18n.t("message.cannot_print"))
            vm.waitingPrint = false
          })
    },
    createMoving(requirement) {
      const payload = {
        mainwarehouse_id: requirement.mainwarehouse_id,
        warehouse_id: requirement.warehouse_id,
        items: requirement.items,
        requirement_id: requirement.id,
      };

      this.save(payload)
        .then((response) => {
          this.$alert(response.message);
        })
        .catch(error => {
          this.$alert(error);
        })
        .finally(fin => {
          this.isItemsModelVisible = false;
          this.refreshData();
        });
    }
  },
};
</script>
<style lang="scss">
.vuee {
  color: #fff;
  border: 1px solid #2475a8;
  background: #2475a8;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: pointer;
}
</style>